.NewReportWrapper {
  .Logo {
    width: 88px;
  }

  .FormWrapper {
    position: relative;
    z-index: 2;
    background-color: white;
    width: 750px;
    min-height: 100vh;

    .InfoWrap {
      margin-bottom: 32px;

      .InfoList {
        padding-left: 20px;
        margin: 0 0 10px;
      }
    }

    form {
      padding: 80px 88px;
    }

    .TableImg {
      width: 100%;
    }

    h1 {
      margin-top: 64px;
      margin-bottom: 16px;
    }

    .Fields {
      display: grid;
      gap: 12px;
    }

    .OtherComment {
      margin-bottom: 0;
    }

    .GuideButtons {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-top: 16px;
      margin-bottom: 64px;

      a {
        flex: 1;
      }
    }

    .SaveButtons {
      display: flex;
      gap: 24px;
      padding-top: 36px;

      button {
        flex: 1;
      }
    }

    .GetDetails {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .FormBackground {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    height: 100vh;
    width: calc(100% - 708px);
    background-image: url('../../../images/ReportFormBackground.png');
    background-position: center;
    background-size: cover;
  }
}

@import '@/styles/Colors.module.scss';

.InitiativesPage {
  display: grid;
  grid-template-columns: 533px 1px 1fr;
  gap: 64px;
  padding: 49px 64px 0;
  height: calc(100% - 90px);
  overflow: hidden;

  .DeleteButton {
    background-color: $red-E0;
    & > span {
      border-color: $red-E0;
    }

    &:hover {
      background-color: $red-CC0;

      & > span {
        box-shadow: none;
      }
    }

    svg {
      color: white;
    }
  }

  .InitiativeList {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .List {
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow-y: auto;
      max-height: calc(100vh - 300px - 70px);
    }

    .Search {
      margin-bottom: 8px;
    }

    .Head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .InitiativeItem {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      padding: 25px;

      border-radius: 4px;
      border: 1px solid $grey-df;
      cursor: pointer;

      .Date {
        color: $black-11;
        font-size: 12px;
        text-align: right;
      }

      .Title {
        grid-column-start: 1;
        grid-column-end: 3;
      }

      &:hover {
        border-color: $blue-00;
        border-width: 2px;
        padding: 24px;
      }

      &.active {
        border-width: 2px;
        border-color: $blue-00;
        padding: 24px;
      }
    }
  }

  .InitiativeDetails {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .Head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .TitleSection {
        display: grid;
        gap: 8px;
      }

      .Controls {
        display: flex;
        gap: 16px;

        button {
          white-space: nowrap;
        }
      }
    }

    .ScrollBlock {
      overflow-y: auto;
      flex: 1;
    }

    .Tabs {
      margin-top: 40px;

      .Panel {
        padding-top: 0;
      }
    }

    .Link {
      color: inherit;
      text-decoration: underline;
    }
  }

  .Divider {
    height: 100%;
    width: 1px;
    background-color: $grey-df;
  }
}

.Information {
  .ContentTable {
    display: grid;
    grid-template-columns: 232px 1fr;
    align-items: center;
    column-gap: 24px;
    row-gap: 16px;
    padding: 24px 0;
    font-size: 14px;
    border-bottom: 1px solid $grey-df;

    &:last-child {
      border: none;
    }

    span {
      font-weight: 700;
    }
  }
}

.DescriptionBlock {
  max-height: 88px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  &.Show {
    max-height: none;
    text-overflow: unset;
    -webkit-line-clamp: unset;
  }
}

.Toggle {
  font-weight: 700;
  background: white;
  cursor: pointer;
  text-align: right;
}

.Status {
  padding: 4px 12px;
  font-weight: 700;
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  background-color: $grey-cc;
  width: fit-content;

  &.rejected {
    background-color: $red-B8;
  }

  &.global {
    background-color: $orange-F2;
  }

  &.country {
    background-color: $blue-00;
  }

  &.draft {
    background-color: $grey-cc;
  }

  &.ready_for_push {
    background-color: $green-0A;
  }

  &.pushed {
    background-color: $green-0A;
  }
}

.Placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 90px);
  width: 100%;

  .PlaceholderImage {
    margin-bottom: 61px;
  }

  .PlaceholderHead {
    margin-bottom: 18px;
  }

  .PlaceholderContent {
    margin-bottom: 24px;
  }
}

.DetailsSelect {
  position: relative;
  width: fit-content;

  .Field {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px;
    height: 40px;
    width: 260px;
    border-radius: 4px;
    border: 1px solid $grey-92;
    cursor: pointer;

    .FieldValue {
      color: $black-11;
    }
  }

  .List {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    z-index: 10;

    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px 0;

    .Item {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 16px;
      padding: 12px 16px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.Attachments {
  display: flex;
  gap: 24px;

  .Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    color: $black-11;
    text-decoration: none;

    max-width: 75px;
    word-break: break-word;
    text-align: center;

    svg {
      color: $grey-92;
    }
  }
}

.InitiativesLeft {
  display: grid;
  align-items: center;
  grid-template-columns: 4px 1fr auto;
  gap: 12px;

  width: 100%;
  height: 54px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);

  .Indicator {
    width: 4px;
    height: 100%;
    background-color: $blue-00;
  }

  .Title {
    font-size: 14px;
    font-weight: 700;
    color: $black-11;
  }

  .Review {
    margin-right: 32px;
    font-size: 12px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    color: $blue-00;
    text-align: right;
  }
}

.More {
  .MoreDropdown {
    :nth-child(1) {
      left: initial;
      right: 20px;
      min-width: 200px;
    }
  }
}

@media (max-width: 1024px) {
  .InitiativesPage {
    grid-template-columns: 350px 1px 1fr;

    .InitiativeDetails {
      .Head {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
}

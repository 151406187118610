@import '@/styles/Colors.module.scss';

.DropZone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 180px;
  background-color: $blue-F1;
  border: 2px dashed $blue-00;

  .ContentText,
  .HeadText {
    color: $blue-00;

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .HelperText {
    color: $grey-92;
  }
}

.Progress {
  background-color: $grey-df;
  height: 5px;
  width: 200px;
  border-radius: 5px;
  margin-bottom: 16px;

  .Indicator {
    height: 100%;
    width: 0;
    background-color: $blue-00;
    border-radius: 5px;
  }
}

.Attachments {
  display: grid;
  gap: 16px;

  margin-top: 24px;

  .AttachmentItemWrap {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .AttachmentItem {
    display: grid;
    grid-template-columns: 24px 1fr 40px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid $grey-df;

    width: 100%;
    padding: 16px;

    &.Error {
      border-color: $red-E0;
    }

    .Content {
      color: $black-11;

      .AddInfo {
        color: $grey-92;
      }
    }
  }
}

@import '@/styles/Colors.module.scss';

.CheckboxGroup {
  .Label {
    color: $black-11;
    margin-bottom: 4px;
  }

  fieldset {
    display: grid;
    gap: 24px;
    border: none;
    margin-top: 16px;
    padding: 0;
  }

  .CheckboxHelper {
    display: block;
    margin-bottom: 24px;
    color: $grey-76;
  }
}

.More {
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

@import '@/styles/Colors.module.scss';

.UserPhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: white;

  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $black-11;
  background-position: center;
  background-size: cover;

  &.Small {
    font-size: 10px;
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
}

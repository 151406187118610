@import 'src/styles/Colors.module';

.Explore {
  padding: 49px 64px 0;

  .FiltersButton {
    background-color: $grey-f5;
  }

  .FilterListButton {
    background-color: $grey-f5;
    border: 1px solid $black-11;
  }

  .Head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Divider {
    height: 1px;
    width: 100%;
    background-color: $grey-df;
    margin: 32px 0;
  }

  .Controls {
    display: grid;
    grid-template-columns: 1fr 120px;
    align-items: center;
    margin-bottom: 32px;
  }

  .ActiveFilters {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .Table {
    .Status {
      width: 100%;
      text-align: center;
    }

    .TableHead {
      display: grid;
      grid-template-columns: 2fr repeat(5, 1fr) 140px;
      align-items: center;
      padding: 12px 0;
      gap: 8px;
      border-bottom: 1px solid $grey-df;

      .TableHeadCell {
        font-size: 12px;
        color: $grey-48;

        &:first-child {
          padding-left: 24px;
        }

        &.Right {
          text-align: right;
        }
      }
    }

    .TableBody {
      :nth-child(even) {
        .TableRow {
          background-color: black;
        }
      }

      .TableRow {
        display: grid;
        grid-template-columns: 2fr repeat(5, 1fr) 140px;
        gap: 8px;
        align-items: center;
        padding: 20px 0;
        font-size: 14px;
        color: $black-11;
        border-bottom: 1px solid $grey-df;

        &:nth-child(odd) {
          background-color: $grey-f5;
        }

        .TableRowCellName {
          font-weight: 700;
          padding-left: 24px;
          text-decoration: underline;
          cursor: pointer;
        }

        .TableRowCellRight {
          justify-self: right;
          width: 100%;
        }
      }
    }
  }
}

.ExploreFilters {
  .Accordion {
    li {
      &:first-child {
        button {
          border-top: none;
        }
      }
    }
  }

  .Footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
  }
}

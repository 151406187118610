.ConfirmationModal {
  h2 {
    margin-top: 0;
  }

  .ModalText {
    margin-bottom: 32px;
  }

  form {
    padding: 0 !important;
  }

  .ButtonHolder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding-top: 20px;
    padding-bottom: 24px;
  }

  .ButtonFull {
    display: grid;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 24px;
  }
}

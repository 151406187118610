@import '@/styles/Colors.module.scss';

.Field {
  label {
    color: $black-11;
  }

  .TextArea {
    textarea {
      font-family: inherit;
      font-size: 1rem;
    }
  }

  .Helper {
    margin-top: 4px;
  }
}

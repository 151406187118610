@import '@/styles/Colors.module.scss';

.FormField {
  position: relative;
  margin-bottom: 0 !important;

  .FilterField {
    background-color: $grey-f5 !important;

    svg {
      width: 1rem;
      margin-left: 14px;
    }
  }
}

$grey-f5: #f5f5f5;
$grey-df: #dfdfdf;
$grey-cc: #cccccc;
$grey-92: #929292;
$grey-76: #767676;
$grey-48: #484848;

$black-11: #111111;

$red-f2: #f2e2e7;
$red-CC: #cc0008;
$red-CC0: #cc003d;
$red-B8: #b80029;
$red-E0: #e00751;

$orange-F2: #f26a2f;

$blue-00: #0058a3;
$blue-F1: #f1f4fe;

$yellow-ff: #ffdb00;

$green-1A: #1aaf40;
$green-0A: #0a8a00;

:export {
  greyF5: $grey-f5;
  greyDF: $grey-df;
  greyCC: $grey-cc;
  grey92: $grey-92;
  grey76: $grey-76;
  grey48: $grey-48;
  black11: $black-11;
  redF2: $red-f2;
  redB8: $red-B8;
  redCC: $red-CC;
  redCC0: $red-CC0;
  redE0: $red-E0;
  orangeF2: $orange-F2;
  blue00: $blue-00;
  blueF1: $blue-F1;
  yellowFF: $yellow-ff;
  green1A: $green-1A;
  green0A: $green-0A;
}

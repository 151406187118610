@import '@/styles/Colors.module.scss';

.AdminPage {
  padding: 49px 64px 0;

  .Search {
    width: 400px;
    height: 40px;
  }

  .Filters {
    margin-bottom: 8px;

    form {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .UserItem {
    display: grid;
    grid-template-columns: 2fr 1fr 300px 200px;
    gap: 8px;
    padding: 12px;
    align-items: center;

    &:nth-child(odd) {
      background-color: $grey-f5;
    }

    .Counties {
      position: relative;
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: flex-end;

      .CountriesLabels {
        text-align: right;
      }
    }
  }
}

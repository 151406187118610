@import '@/styles/Colors.module.scss';

.Field {
  label {
    color: $black-11;
  }
}

.DropdownList {
  position: relative;
  width: 100%;

  .DropdownListWrapper {
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    z-index: 10000;
    min-width: 300px;
    max-height: 270px;
    background-color: white;
    padding: 24px 0;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 16px;
      gap: 8px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .None {
      height: 24px;
      padding: 16px;
      width: 100%;
    }
  }
}

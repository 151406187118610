@import '@/styles/Colors.module.scss';

.NotificationItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 40px;
  border-bottom: 1px solid $grey-df;
  cursor: pointer;

  .Head {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .UserName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .TypeDesc {
    color: $grey-92;
    flex: 1;
    white-space: nowrap;
  }

  .Indicator {
    min-width: 8px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $blue-00;
  }

  .Description {
    color: $grey-92;
  }

  .Date {
    font-size: 12px;
  }
}

.NoNotifications {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 72px);

  img {
    margin-bottom: 24px;
  }
}

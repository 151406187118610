@import '@/styles/Colors.module.scss';

.Modal {
  .DetailsModal {
    padding-top: 0;
    padding-bottom: 0;

    h1 {
      font-weight: 700;
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    & > div {
      color: $black-11;
    }

    h1 {
      margin: 0;
    }

    .ContentText {
      line-height: 20px;
    }
  }

  ol,
  ul {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 24px;
    color: $black-11;

    li {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    padding-left: 0;
    list-style-type: '';
  }

  ul {
    list-style-type: '•  ';
  }

  .DetailsModalFooter {
    border-top: 1px solid $grey-df;

    a {
      width: 100% !important;
    }
  }

  .TableRowHead,
  .TableRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .TableRowHead {
    border-bottom: 1px solid $grey-df;

    center,
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 12px 24px;
      color: $grey-48;
    }
  }

  .TableRow {
    border-bottom: 1px solid $grey-df;

    center,
    span {
      padding: 20px 24px;
      color: $black-11;
      font-size: 14px;
    }
  }
}

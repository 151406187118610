@import '@/styles/Colors.module.scss';

.Wrapper {
  padding: 24px 0;

  form {
    display: grid;
    grid-template-columns: 1fr 120px;
    gap: 24px;

    button {
      margin-top: 4px;
    }

    .TextBox {
      textarea {
        max-height: 44px;
        min-height: 44px;
      }
    }
  }
}

.ActivityTab {
  position: relative;
  display: grid;
  gap: 24px;
  padding: 24px 0;

  &:after {
    position: absolute;
    top: 24px;
    left: 20px;
    z-index: -1;
    content: '';

    width: 1px;
    height: calc(100% - 48px);
    background-color: $grey-df;
  }

  .ActivityItem {
    display: grid;
    gap: 8px;

    .BaseInfoWrap {
      display: flex;
    }

    .BaseInfo {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 12px;
      flex: 1;
    }

    .CommentInfo {
      color: $grey-48;
      flex: 1;
      min-width: 100%;
      margin-top: 8px;
    }
  }

  .UserName {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: $black-11;
    height: 40px;
    margin-right: 4px;
  }

  .Description {
    color: $grey-92;
    margin-right: 8px;
  }

  .Date {
    flex: 1;
    color: $grey-48;
    text-align: right;
  }
}

@import '@/styles/Colors.module.scss';

.Modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  position: relative;
  width: 1080px;
  height: 910px;
  background-color: white;

  h2 {
    margin-top: 0;
  }

  & > div {
    max-width: initial;
  }
}

.CloseBtn {
  cursor: pointer;
  margin-right: 8px;
}

.Heading {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  width: 100%;
}

.Body {
  flex: 1;
  overflow-y: auto;
  margin-top: 40px;

  .Table {
    .HeadRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      font-size: 12px;
      color: $grey-48;
      border-bottom: 1px solid $grey-df;

      &.Units {
        grid-template-columns: 2fr 1fr;
      }

      .HeadItem {
        padding: 12px 24px;
      }
    }

    .TableRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      border-bottom: 1px solid $grey-df;

      &.Units {
        grid-template-columns: 2fr 1fr;
      }

      &:nth-child(even) {
        background-color: $grey-f5;
      }

      .RowCel {
        padding: 20px 24px;

        &.IsZero {
          background-color: $red-f2;
        }
      }
    }
  }
}

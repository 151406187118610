@import '@/styles/Colors.module.scss';

.AnalyticsPageWrap {
  padding: 40px 64px;

  .QuickInfoBlockFY24,
  .QuickInfoBlock {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
    margin-bottom: 27px;

    .QuickInfoCardFY24,
    .QuickInfoCard {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px 48px;
      gap: 32px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      .TitleBlock {
        .Title {
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          color: $grey-76;
          margin-top: 8px;
          text-transform: uppercase;
        }
      }

      .RightSide {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background-color: rgba($green-1A, 0.3);

        span {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 52px;
          height: 52px;
          border-radius: 50%;
          background-color: white;

          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          color: $green-1A;
        }
      }
    }

    .QuickInfoCardFY24 {
      flex-direction: row;
      align-items: center;
      padding: 32px 64px;
      height: 126px;

      .Title {
        margin-top: 0;
        margin-bottom: 8px;
      }
    }
  }

  .QuickInfoBlockFY24 {
    grid-template-columns: repeat(2, 1fr);
  }

  .Inline {
    margin-bottom: 32px;
  }

  .ChartsBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    .ChartCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      padding: 24px;
      height: 100%;
      border-radius: 4px;

      .Head {
        margin-bottom: 24px;
        align-self: flex-start;
      }

      .ChartWrap {
        display: flex;
        align-items: center;
        gap: 32px;
        margin-bottom: 40px;
      }

      .ChartQuickInfoWrap {
        position: relative;

        .ChartQuickInfo {
          display: flex;
          flex-direction: column;
          align-items: center;

          position: absolute;
          top: calc(50% - 32px);
          left: calc(50% - 50px);
          z-index: 1;
          height: 64px;
          width: 100px;

          .QuickInfoTitle {
            color: $grey-76;
            margin-bottom: 4px;
          }
        }
      }

      .ChartInfo {
        display: grid;
        gap: 32px;

        .InfoItem {
          .InfoItemHead {
            display: flex;
            align-items: center;
            gap: 16px;

            .Indicator {
              height: 16px;
              width: 16px;
              border-radius: 50%;
            }

            .Name {
              color: $grey-76;
            }
          }
        }
      }
    }
  }

  .ContentTable {
    margin-top: 40px;

    .Right {
      text-align: right;
      padding-right: 24px;
    }

    .TableHead {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      border-bottom: 1px solid $grey-df;

      .HeadItem {
        padding: 12px 24px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 700;
        color: $grey-76;
      }
    }

    .TableRowWrap {
      padding: 22px 0;
      border-bottom: 1px solid $grey-df;
    }

    .TableTotal {
      padding: 22px 0;

      .TotalLabel {
        padding-left: 40px;
      }
    }

    .TableRow {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      font-weight: 700;

      .RowItem {
        padding: 0 24px;
      }

      .RowItemToggle {
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;

        .RowItemToggleIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background-color: $grey-f5;
        }

        .ToggleIconHolder {
          height: 24px;
          width: 24px;
        }
      }
    }

    .ExpandableBlock {
      display: none;

      .ExpandableBlockItem {
        margin-top: 8px;
        display: grid;
        grid-template-columns: repeat(7, 1fr);

        .Cell {
          padding-left: 24px;
        }
      }

      .Highlighted {
        font-weight: bold;
      }

      &.Show {
        display: block;
      }
    }
  }
}

.PrivacyWrap {
  display: flex;
  justify-content: center;
  padding: 40px 64px;

  .Privacy {
    max-width: 600px;

    .Head {
      margin-bottom: 24px;
    }

    .Block {
      margin-bottom: 24px;
    }
  }
}
